import styled from "styled-components";
import styleguide from "@ui/styleguide";

export const Container = styled.div`
  display: block;
  padding: 0px 24px;
  margin: 0 auto;
  // min-height: 100vh;

  @media (min-width: ${styleguide.breakpoints.tablet}) {
    padding: 0px 64px;
  }

  @media (min-width: ${styleguide.breakpoints.desktop}) {
    width: 968px;
    padding: 0px;
  }
`;
