import { color, colorProps, textAlign, textAlignProps } from "@ui/concerns";
import styleguide from "@ui/styleguide";
import { HeadingLevel } from "@ui/types";
import React from "react";
import styled, { css } from "styled-components";

const style = css`
  ${color}
  ${textAlign}

  font-family: ${styleguide.fonts.heading};
  line-height: 1.2;
  margin: 0;
  padding: 0;
`;

const Hero = styled.h1`
  ${style}

  font-size: ${styleguide.fontSizes[32]};
  font-weight: ${styleguide.fontWeights.bold};

  @media (min-width: ${styleguide.breakpoints.desktop}) {
    font-size: ${styleguide.fontSizes[48]};
  }
`;

const H1 = styled.h1`
  ${style}

  font-size: ${styleguide.fontSizes[24]};
  font-weight: ${styleguide.fontWeights.bold};

  @media (min-width: ${styleguide.breakpoints.desktop}) {
    font-size: ${styleguide.fontSizes[32]};
  }
`;

const H2 = styled.h2`
  ${style}

  font-size: ${styleguide.fontSizes[22]};
  font-weight: ${styleguide.fontWeights.semibold};

  @media (min-width: ${styleguide.breakpoints.desktop}) {
    font-size: ${styleguide.fontSizes[28]};
  }
`;

const H3 = styled.h3`
  ${style}

  font-size: ${styleguide.fontSizes[18]};
  font-weight: ${styleguide.fontWeights.semibold};

  @media (min-width: ${styleguide.breakpoints.desktop}) {
    font-size: ${styleguide.fontSizes[24]};
  }
`;

type props = colorProps &
  textAlignProps & {
    children: React.ReactNode;
    level: HeadingLevel;
  };

export const Heading = ({ children, level, $color, $textAlign }: props) => {
  switch (level) {
    case "hero":
      return (
        <Hero $color={$color} $textAlign={$textAlign}>
          {children}
        </Hero>
      );
    case "h1":
      return (
        <H1 $color={$color} $textAlign={$textAlign}>
          {children}
        </H1>
      );
    case "h2":
      return (
        <H2 $color={$color} $textAlign={$textAlign}>
          {children}
        </H2>
      );
    case "h3":
      return (
        <H3 $color={$color} $textAlign={$textAlign}>
          {children}
        </H3>
      );
  }
};
