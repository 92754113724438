import styleguide from "@ui/styleguide";
import styled from "styled-components";

export const Badge = styled.span`
  font-family: ${styleguide.fonts.body};
  font-size: ${styleguide.fontSizes[16]};

  padding: 0px 10px;
  border-radius: 25px;
  background: ${styleguide.colors.violet[200]};
  color: ${styleguide.colors.violet[300]};
`;
