const styleguide = {
  breakpoints: {
    mobile: "0px",
    tablet: "768px",
    desktop: "1024px",
  },

  fonts: {
    heading: "Manrope",
    body: "Manrope",
  },

  fontSizes: {
    14: "0.875rem",
    16: "1rem",
    18: "1.125rem",
    22: "1.25rem",
    24: "1.5rem",
    28: "1.75rem",
    32: "2rem",
    48: "3rem",
  },

  fontWeights: {
    regular: 400,
    semibold: 600,
    bold: 700,
  },

  borderRadius: {
    regular: "30px",
  },

  colors: {
    white: "#FFFFFF",
    black: "#000000",
    purple: "#271830",
    gray: {
      100: "#F9F9F9",
      200: "#ECEDF0",
      300: "#B3BBC9",
      400: "#4B5563",
    },
    violet: {
      100: "#DDD6FE",
      200: "#C4B5FD",
      300: "#8B5CF6",
    },
    red: {
      100: "#F2E2E8",
      200: "#F0C0C6",
      300: "#DB2B41",
    },
    cian: {
      100: "#DCF1F4",
      200: "#BFE6EC",
      300: "#2892AF",
    },
  },
};

export default styleguide;
