import { Link as InertiaLink } from "@inertiajs/react";
import { display, displayProps } from "@ui/concerns";
import styleguide from "@ui/styleguide";
import styled, { css } from "styled-components";

type props = displayProps & {
  $color?: string;
  $fontWeight?: number;
};

const style = css<props>`
  ${display}

  color: ${(props) => props.$color || styleguide.colors.black};

  font-family: ${styleguide.fonts.heading};
  font-weight: ${(props) =>
    props.$fontWeight || styleguide.fontWeights.semibold};
  text-decoration: none;
`;

export const Link = styled(InertiaLink)<props>`
  ${style}
`;

export const NormalLink = styled.a<props>`
  ${style}
`;
