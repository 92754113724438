import styleguide from "@ui/styleguide";
import { css } from "styled-components";

export type colorProps = {
  $color?: string;
};

export const color = css<colorProps>`
  ${({ $color }) => {
    if (!$color) {
      return `color: ${styleguide.colors.black}`;
    }

    return `color: ${$color};`;
  }};
`;
