import { PostModel } from "@blog/types";
import { Badge, Heading, RichText, Stack, Text } from "@ui/lib";
import styleguide from "@ui/styleguide";
import DOMPurify from "dompurify";
import { DateTime } from "luxon";
import styled from "styled-components";

const Content = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${styleguide.breakpoints.desktop}) {
    width: 800px;
  }
`;

type props = {
  post: PostModel;
};

export default function Post({ post }: props) {
  return (
    <Content>
      <Stack $spacing={24}>
        <Stack $spacing={8} $alignItems={"flex-start"}>
          <Badge>{post.tag}</Badge>
          <Heading level="h1">{post.title}</Heading>
          <Text level="p1" $color={styleguide.colors.gray[400]}>
            {DateTime.fromISO(post.date, { locale: "pt-BR" }).toFormat(
              "dd LLL yyyy"
            )}
          </Text>
        </Stack>

        <RichText
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }}
        />
      </Stack>
    </Content>
  );
}
