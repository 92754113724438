import styleguide from "@ui/styleguide";
import { ResponsiveValue, TextAlign } from "@ui/types";
import { css } from "styled-components";

export type textAlignProps = {
  $textAlign?: ResponsiveValue<TextAlign>;
};

export const textAlign = css<textAlignProps>`
  ${({ $textAlign }) => {
    if (!$textAlign) {
      return;
    }

    if (typeof $textAlign === "string") {
      return `text-align: ${$textAlign};`;
    }

    return `
      text-align ${$textAlign.mobile};

      ${
        $textAlign.tablet &&
        `@media (min-width: ${styleguide.breakpoints.tablet}) {
            text-align ${$textAlign.tablet};
        }`
      }

      ${
        $textAlign.desktop &&
        `@media(min-width: ${styleguide.breakpoints.desktop}) {
            text-align ${$textAlign.desktop};
        }`
      }
    `;
  }};
`;
