import '@ui/fonts.css';

import Footer from "@blog/components/Footer";
import Header from "@blog/components/Header";
import { Container } from "@ui/lib";
import React from "react";

interface props {
  children: React.ReactNode;
}

export default function Layout({ children }: props) {
  return (
    <>
      <Container>
        <Header />
        <main>{children}</main>
      </Container>
      <Footer />
    </>
  );
}
