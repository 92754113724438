import { Link, Stack } from "@ui/lib";
import Logo from "@ui/logo_white.svg?react";
import styleguide from "@ui/styleguide";
import { Instagram, Linkedin, Youtube } from "lucide-react";
import styled from "styled-components";

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 64px 0px 24px;
  padding: 0px 24px;

  @media (min-width: ${styleguide.breakpoints.desktop}) {
    margin: 64px 0px 56px;
    padding: 0px 56px;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 96px 0px;
  gap: 48px;
  border-radius: ${styleguide.borderRadius.regular};

  background: black;
  background: radial-gradient(
    circle at 50% 120%,
    ${styleguide.colors.violet[300]} 0%,
    ${styleguide.colors.black} 35%
  );

  @media (min-width: ${styleguide.breakpoints.tablet}) {
    padding: 96px 40px;
    width: 1048px;
    flex-direction: row;
    justify-content: space-between;
    background: radial-gradient(
      ellipse at 50% 120%,
      ${styleguide.colors.violet[300]} 0%,
      ${styleguide.colors.black} 35%
    );
  }

  @media (min-width: ${styleguide.breakpoints.desktop}) {
    padding: 96px 100px;
    width: 1168px;
  }
`;

export default function () {
  return (
    <Footer>
      <FooterContainer>
        <Stack $spacing={32}>
          <Logo width={116} />

          <Stack $spacing={22} $direction={"row"}>
            <Youtube size={24} color={styleguide.colors.white} />
            <Linkedin size={24} color={styleguide.colors.white} />
            <Instagram size={24} color={styleguide.colors.white} />
          </Stack>
        </Stack>

        <Stack
          $spacing={{ mobile: 24, tablet: 50, desktop: 80 }}
          $alignItems={{ mobile: "center", tablet: "flex-start" }}
          $direction={{ mobile: "column", tablet: "row" }}
        >
          <Stack
            $spacing={2}
            $alignItems={{ mobile: "center", tablet: "flex-start" }}
          >
            <Link href="" $color={styleguide.colors.white}>
              Funcionalidades
            </Link>
            <Link href="" $color={styleguide.colors.white}>
              Preços
            </Link>
            <Link href="" $color={styleguide.colors.white}>
              Perguntas frequentes
            </Link>
            <Link href="" $color={styleguide.colors.white}>
              Contato
            </Link>
            <Link href="" $color={styleguide.colors.white}>
              Blog
            </Link>
          </Stack>
          <Stack
            $spacing={2}
            $alignItems={{ mobile: "center", tablet: "flex-start" }}
          >
            <Link href="" $color={styleguide.colors.white}>
              Política de cookies
            </Link>
            <Link href="" $color={styleguide.colors.white}>
              Política de privacidade
            </Link>
            <Link href="" $color={styleguide.colors.white}>
              Termos de uso
            </Link>
          </Stack>
        </Stack>
      </FooterContainer>
    </Footer>
  );
}
