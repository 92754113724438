import { color, colorProps, textAlign, textAlignProps } from "@ui/concerns";
import styleguide from "@ui/styleguide";
import { TextLevel } from "@ui/types";
import React from "react";
import styled, { css } from "styled-components";

const style = css`
  ${color}
  ${textAlign}

  font-family: ${styleguide.fonts.heading};
  font-weight: ${styleguide.fontWeights.regular};
  margin: 0;
  padding: 0;
`;

const P1 = styled.p`
  ${style}

  font-size: ${styleguide.fontSizes[16]};

  @media (min-width: ${styleguide.breakpoints.desktop}) {
    font-size: ${styleguide.fontSizes[18]};
  }
`;

const P2 = styled.p`
  ${style}

  font-size: ${styleguide.fontSizes[14]};

  @media (min-width: ${styleguide.breakpoints.desktop}) {
    font-size: ${styleguide.fontSizes[16]};
  }
`;

type props = colorProps & textAlignProps & {
  children: React.ReactNode;
  level: TextLevel;
};

export const Text = ({ children, level, $color, $textAlign }: props) => {
  switch (level) {
    case "p1":
      return <P1 $color={$color} $textAlign={$textAlign}>{children}</P1>;
    case "p2":
      return <P2 $color={$color} $textAlign={$textAlign}>{children}</P2>;
  }
};
