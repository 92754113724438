import styleguide from "@ui/styleguide";
import { Display, ResponsiveValue } from "@ui/types";
import { css } from "styled-components";

export type displayProps = {
  $display?: ResponsiveValue<Display>;
};

export const display = css<displayProps>`
  ${({ $display }) => {
    if (!$display) {
      return;
    }

    if (typeof $display === "string") {
      return `display: ${$display};`;
    }

    return `
      display: ${$display.mobile};

      ${
        $display.tablet &&
        `@media (min-width: ${styleguide.breakpoints.tablet}) {
        display: ${$display.tablet};
      }`
      }

      ${
        $display.desktop &&
        `@media(min-width: ${styleguide.breakpoints.desktop}) {
        display: ${$display.desktop};
      }`
      }
    `;
  }};
`;
