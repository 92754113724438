import styleguide from "@ui/styleguide";
import {
  AlignItems,
  Direction,
  JustifyContent,
  ResponsiveValue,
} from "@ui/types";
import React from "react";
import styled from "styled-components";

type StackProps = {
  children: React.ReactNode;
  $spacing: ResponsiveValue<number>;
  $direction?: ResponsiveValue<Direction>;
  $alignItems?: ResponsiveValue<AlignItems>;
  $justifyContent?: ResponsiveValue<JustifyContent>;
};

type StyledProps = Required<
  Pick<
    StackProps,
    "$spacing" | "$direction" | "$alignItems" | "$justifyContent"
  >
>;

const Container = styled.div<StyledProps>`
  display: flex;

  ${({ $justifyContent }) => {
    if (typeof $justifyContent === "string") {
      return `justify-content: ${$justifyContent};`;
    }

    return `
      justify-content: ${$justifyContent.mobile};

      @media (min-width: ${styleguide.breakpoints.tablet}) {
        justify-content: ${$justifyContent.tablet};
      }

      @media (min-width: ${styleguide.breakpoints.desktop}) {
        justify-content: ${$justifyContent.desktop};
      }
    `;
  }};

  ${({ $alignItems }) => {
    if (typeof $alignItems === "string") {
      return `align-items: ${$alignItems};`;
    }

    return `
      align-items: ${$alignItems.mobile};

      @media (min-width: ${styleguide.breakpoints.tablet}) {
        align-items: ${$alignItems.tablet};
      }

      @media (min-width: ${styleguide.breakpoints.desktop}) {
        align-items: ${$alignItems.desktop};
      }
    `;
  }};

  ${({ $direction }) => {
    if (typeof $direction === "string") {
      return `flex-direction: ${$direction};`;
    }

    return `
      flex-direction: ${$direction.mobile};

      @media (min-width: ${styleguide.breakpoints.tablet}) {
        flex-direction: ${$direction.tablet};
      }

      @media (min-width: ${styleguide.breakpoints.desktop}) {
        flex-direction: ${$direction.desktop};
      }
    `;
  }};

  ${({ $spacing }) => {
    if (typeof $spacing === "number") {
      return `gap: ${$spacing}px;`;
    }

    return `
      gap: ${$spacing.mobile}px;

      @media (min-width: ${styleguide.breakpoints.tablet}) {
        gap: ${$spacing.tablet}px;
      }

      @media (min-width: ${styleguide.breakpoints.desktop}) {
        gap: ${$spacing.desktop}px;
      }
    `;
  }};
`;

export const Stack = ({
  children,
  $spacing,
  $direction = "column",
  $alignItems = "stretch",
  $justifyContent = "flex-start",
}: StackProps) => {
  return (
    <Container
      $spacing={$spacing}
      $direction={$direction}
      $alignItems={$alignItems}
      $justifyContent={$justifyContent}
    >
      {children}
    </Container>
  );
};
