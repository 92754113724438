import { Button, Link, NormalLink, Stack } from "@ui/lib";
import Logo from "@ui/logo.svg?react";
import styleguide from "@ui/styleguide";
import { Menu as MenuIcon, MoveRight as MoveRightIcon } from "lucide-react";
import styled from "styled-components";
import { Config } from "../../../config";

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  margin-bottom: 64px;

  @media (min-width: ${styleguide.breakpoints.tablet}) {
    height: 112px;
  }
`;

const Menu = () => (
  <Button $display={{ mobile: "block", tablet: "none" }}>
    <MenuIcon />
  </Button>
);

export default function () {
  return (
    <Header>
      <Link href={Config.blogUrl}>
        <Logo width={112} />
      </Link>

      <Stack $spacing={48} $direction={"row"} $justifyContent={"flex-end"}>
        <Menu />

        <NormalLink
          href={Config.siteUrl}
          $fontWeight={styleguide.fontWeights.bold}
          $display={{ mobile: "none", tablet: "block" }}
        >
          Sobre a plataforma
        </NormalLink>
        <NormalLink
          href={Config.backofficeUrl}
          $fontWeight={styleguide.fontWeights.bold}
          $display={{ mobile: "none", tablet: "block" }}
        >
          Entrar <MoveRightIcon size={19} style={{ marginLeft: "6px" }} />
        </NormalLink>
      </Stack>
    </Header>
  );
}
