import { PostModel } from "@blog/types";
import { Link } from "@inertiajs/react";
import { Badge, Divider, Heading, Stack, Text } from "@ui/lib";
import styleguide from "@ui/styleguide";
import { Fragment } from 'react';
import styled from "styled-components";

const Content = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${styleguide.breakpoints.desktop}) {
    width: 800px;
  }
`;

const Wrapper = styled.div`
  width: 310px;

  @media (min-width: ${styleguide.breakpoints.tablet}) {
    width: 435px;
  }

  @media (min-width: ${styleguide.breakpoints.desktop}) {
    width: 550px;
  }
`;

type props = {
  posts: PostModel[];
}

export default function Home({ posts }: props) {
  return (
    <Content>
      <Stack $spacing={40} $alignItems={"center"}>
        <Wrapper>
          <Stack $spacing={24} $alignItems={"center"}>
            <Heading level="hero">Nosso blog</Heading>
            <Heading
              level="h3"
              $color={styleguide.colors.gray[400]}
              $textAlign={"center"}
            >
              Aqui você encontra dicas para aproveitar ao máximo nossa plataforma e
              simplificar seu fluxo de trabalho.
            </Heading>
          </Stack>
        </Wrapper>

        <Divider />

        {posts.map((post, index) => (
          <Fragment key={post.id}>
            <Wrapper>
              <Link href={post.url}>
                <Stack $spacing={8} $alignItems={"center"}>
                  <Badge>{post.tag}</Badge>
                  <Heading level="h1" $textAlign={"center"}>{post.title}</Heading>
                  <Text level="p1" $color={styleguide.colors.gray[400]}>14 Jul 2024</Text>
                </Stack>
              </Link>
            </Wrapper>

            {(index < posts.length - 1) && <Divider />}
          </Fragment>
        ))}
      </Stack>
    </Content>
  );
}
