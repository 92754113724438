import Layout from "@blog/pages/Layout";
import { createInertiaApp } from "@inertiajs/react";
import { createElement } from "react";
import { createRoot } from "react-dom/client";
import "@ui/reset.css";
import "sanitize.css";

createInertiaApp({
  title: (title) => (title ? `${title} - Rybo` : "Rybo"),

  resolve: (name: string) => {
    const pages = import.meta.glob("../apps/blog/pages/**/*.tsx", {
      eager: true,
    });

    const page = pages[`../apps/blog/pages/${name}.tsx`] as any;
    page.default.layout ||= (page: any) => createElement(Layout, null, page);

    return page;
  },

  setup({ el, App, props }) {
    const root = createRoot(el);
    root.render(createElement(App, props));
  },
});
