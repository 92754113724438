import * as React from "react";
const SvgLogo = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 1096.18 467.79", ...props }, /* @__PURE__ */ React.createElement("title", null, "logo-01"), /* @__PURE__ */ React.createElement("g", { id: "Camada_2", "data-name": "Camada 2" }, /* @__PURE__ */ React.createElement("path", { d: "M.5,382.42l26-297H95.83l-2.48,28.47a128.42,128.42,0,0,1,34.26-21.78c14-6.24,30.33-9.41,48.42-9.41h19L189,151.92H170.5c-16.52,0-30.67,4.32-43.26,13.21a102.74,102.74,0,0,0-31.48,36.14,126.2,126.2,0,0,0-14.3,48.46L69.88,382.42Z", style: {
  fill: "#1d1d1b"
} }), /* @__PURE__ */ React.createElement("path", { d: "M771.38,158.41a123.07,123.07,0,0,0-46.33-55.67C704.21,89,678.48,82.09,648.57,82.09c-26.44,0-48.63,4.58-70,15.55,6.4-17.87,24.68-32.7,48.53-32.7.58,0,1.15,0,1.73,0a48.94,48.94,0,0,1,9.55,1.29l5.7-65.14A103.24,103.24,0,0,0,632.77.5,126.23,126.23,0,0,0,593.9,6.76c-52.5,17-88.19,61.8-93.14,116.89-3.46,38.6-6.07,68.14-8.91,100.73l-1.29,14.76c-1.66,19-3.44,39.53-5.54,63.76-2,22.93-3.91,45-5.69,65.43-.18,2.08-1.31,14.09-1.31,14.09h69l1.66-19c21,13.64,46.57,20.55,76.06,20.55s56.24-6.81,79-20.26a154,154,0,0,0,54.63-54.51,174.71,174.71,0,0,0,23.86-74.88C784.73,206.71,781.05,181.15,771.38,158.41ZM712.9,231.74a110.91,110.91,0,0,1-13.11,43.91,83.53,83.53,0,0,1-28.94,31.18c-12,7.64-26,11.35-42.93,11.35-16.3,0-29.28-3.58-39.67-10.95a61.07,61.07,0,0,1-22.5-29.62c-4.88-13.09-6.66-27.64-5.3-43.25a110.45,110.45,0,0,1,13.11-44.12,83.13,83.13,0,0,1,29.14-30.92c12.17-7.67,26.15-11.4,42.74-11.4,16.37,0,29.78,3.67,39.85,10.89a62.18,62.18,0,0,1,22.36,29.47C712.53,201.21,714.3,215.83,712.9,231.74Z", style: {
  fill: "#1d1d1b"
} }), /* @__PURE__ */ React.createElement("path", { d: "M937.43,383.33c-29.54,0-55.16-6.94-76.16-20.61a122.22,122.22,0,0,1-46.65-56c-9.64-22.9-13.32-48.34-10.93-75.63,2.4-27.47,10.45-52.76,23.91-75.19a153.9,153.9,0,0,1,54.9-54.24c23-13.42,49.44-20.23,78.72-20.23,29.9,0,55.63,6.95,76.47,20.65A123.13,123.13,0,0,1,1084,157.72c9.67,22.75,13.35,48.3,10.93,75.95a174.67,174.67,0,0,1-23.86,74.88,153.94,153.94,0,0,1-54.64,54.52C993.66,376.51,967.07,383.33,937.43,383.33Zm20.65-236.09c-16.58,0-30.56,3.73-42.74,11.39a83.23,83.23,0,0,0-29.14,30.93,110.34,110.34,0,0,0-13.1,44.11c-1.37,15.61.41,30.17,5.29,43.26a61.23,61.23,0,0,0,22.5,29.62c10.4,7.36,23.37,10.94,39.67,10.94,16.92,0,31-3.71,42.94-11.34A83.64,83.64,0,0,0,1012.44,275a111,111,0,0,0,13.11-43.9c1.39-15.92-.37-30.54-5.25-43.46a62.16,62.16,0,0,0-22.37-29.48C987.87,150.9,974.46,147.24,958.08,147.24Z", style: {
  fill: "#1d1d1b"
} }), /* @__PURE__ */ React.createElement("path", { d: "M401.86,85.37l-7,79.61c-2.64,30-5.36,61-8,91.51-1.2,13.89-6.17,25.23-15.21,34.67-10.06,10.52-26.1,19-40.28,18.7a59.93,59.93,0,0,1-23.11-4.46c-18-7-28.45-24.77-26.76-45,2.58-30.75,5.33-62,8-92.26q1.7-19.16,3.37-38.33L297,85.37H227.79L223.86,128c-1,11.28-2.06,22.76-3.09,33.86-2.59,27.75-5.26,56.45-6.89,84.69a155.75,155.75,0,0,0,4.53,48.22c5.87,22.17,19.79,42.12,39.21,56.15a113.68,113.68,0,0,0,66.14,21.33h0A111.9,111.9,0,0,0,344,370.4c8.23-1.5,27.77-7,29.5-7.53a50.62,50.62,0,0,1-51.65,40c-25.11-1.41-38.1-17.6-43.13-28.07H205.19c.41,2.1.87,4,1.31,6.06,8.87,41.05,49.28,86.45,109.65,86.45A126.23,126.23,0,0,0,355,461c52.5-17,88.19-61.8,93.14-116.89,5.94-66.06,9.34-105.57,15.75-179.25,2-22.93,3.91-45,5.69-65.43.18-2.09,1.31-14.09,1.31-14.09Z", style: {
  fill: "#1d1d1b"
} })));
export default SvgLogo;
